import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import SetCustomMark from '@atlassian/jira-providers-spa-apdex-analytics/src/set-custom-mark/index.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { getRapidBoardDataResource } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { useRapidBoardResource } from '../../controllers/resources';
import { useWrmBundleLoader } from '../../controllers/wrm-bundle-state';
import type { PerformanceMarkProps } from './types';
import { getApdexAttributes, getBM3Metric } from './utils';

export const PerformanceMarks = ({ isRendered, isInteractive }: PerformanceMarkProps) => {
	const [{ route, query }] = useRouter();

	const { hasGHContextLoaded } = useWrmBundleLoader();
	const { data } = useRapidBoardResource({
		resource: getRapidBoardDataResource(),
	});

	const metric = getBM3Metric(route.name);

	// @ts-expect-error - TS2339
	const allDataRequestTraceId = data?.allDataTraceId;

	const metricTag = metric && (
		<SubmitApdex
			appName={route.name}
			key={route.name}
			extra={{ allDataRequestTraceId, ...getApdexAttributes(query) }}
			// @ts-expect-error - TS2769 - No overload matches this call.
			metric={metric}
			emitOnAnimationFrame={ff('bm3.emit-on-raf.top-experiences')}
			waitForLongTaskIdleTTI={ff('jsw-measure-tti-alternate')}
		/>
	);

	if (!metricTag) {
		// eslint-disable-next-line no-console
		console.warn('Performance metric not defined for this route');
	}

	return (
		<>
			<UFOLoadHold name="is-interactive" hold={!isInteractive} />
			{hasGHContextLoaded && <SetCustomMark name="wrm_done" />}
			{isRendered && <SetCustomMark name="classic_software_ttr" />}
			{isInteractive && route && metricTag}
		</>
	);
};
