import {
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	REASON_BOARD_CRITICAL_DATA_FAILED,
	REPORT_NAMES,
	REASON_BOARD_CRITICAL_DATA_FAILED_WITH_STATUS_CODE,
} from './constants';
import type { DataAttribute, SLAError } from './types';

const getReportExperienceName = (reportName: string): string => {
	if (reportName === undefined) {
		return 'viewUnknownChart';
	}
	return `view${reportName[0].toUpperCase() + reportName.substring(1)}`;
};

export const isUserNetworkError = (errorName: string): boolean =>
	errorName === 'TypeError' || errorName === 'AbortError' || errorName === 'ChunkLoadError';

export const getExperienceId = (
	rapidView: number | string,
	projectKey: string,
	name: string,
	chart: string,
) => {
	const key = `RapidBoardSPA_${projectKey || ''}_${rapidView}_`;
	if (name === ROUTE_NAMES_RAPIDBOARD_BACKLOG || name === ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG) {
		return `${key}_backlog`;
	}
	if (name === ROUTE_NAMES_RAPIDBOARD_BOARD || name === ROUTE_NAMES_RAPIDBOARD_USER_BOARD) {
		return `${key}_board`;
	}

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly burndownChart: "sprintBurndown"; readonly burnupChart: "burnup"; readonly sprintRetrospective: "sprintReport"; readonly cumulativeFlowDiagram: "cumulativeFlowDiagram"; readonly velocityChart: "velocity"; readonly unknown: "unknown"; }'.
	return `${key}_${getReportExperienceName(REPORT_NAMES[chart])}`;
};

export const getStatusCode = (error: SLAError): number | null => {
	if (error !== null && error !== undefined) {
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'SLAError'.
		const { statusCode } = error || {};
		return statusCode === null || statusCode === undefined ? null : statusCode;
	}
	return null;
};

export const getDataAttributes = (): DataAttribute | null => {
	const { GH } = window;
	if (GH && GH.RapidViewConfig && GH.RapidBoard) {
		const rapidViewConfig = GH.RapidViewConfig.currentData.data;
		return {
			taskId: rapidViewConfig ? rapidViewConfig.id : -1,
			boardType: GH.RapidBoard.State.getBoardTypeForAnalytics(),
		};
	}
	return null;
};
export const getFailedReason = (error: SLAError): string => {
	// Ideally error will never be null or undefined but need to check due to flow type of RouteResourceError
	if (error !== null && error !== undefined) {
		const statusCode = getStatusCode(error); // Will return null or actual value

		return statusCode === null
			? REASON_BOARD_CRITICAL_DATA_FAILED
			: REASON_BOARD_CRITICAL_DATA_FAILED_WITH_STATUS_CODE;
	}
	return REASON_BOARD_CRITICAL_DATA_FAILED;
};
export const getExperienceAttributes = (
	name: string,
	edition: ApplicationEdition,
	chart: string,
) => {
	if (name === ROUTE_NAMES_RAPIDBOARD_BACKLOG) {
		return {
			experience: 'viewBacklog',
			analyticsSource: 'backlog',
			edition,
			application: JIRA_SOFTWARE,
		};
	}
	if (name === ROUTE_NAMES_RAPIDBOARD_BOARD) {
		return {
			experience: 'viewBoard',
			analyticsSource: 'board',
			edition,
			application: JIRA_SOFTWARE,
		};
	}
	return {
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly burndownChart: "sprintBurndown"; readonly burnupChart: "burnup"; readonly sprintRetrospective: "sprintReport"; readonly cumulativeFlowDiagram: "cumulativeFlowDiagram"; readonly velocityChart: "velocity"; readonly unknown: "unknown"; }'.
		experience: getReportExperienceName(REPORT_NAMES[chart]),
		analyticsSource: 'board',
		edition,
		application: JIRA_SOFTWARE,
	};
};

export const isBoardSettings = (config?: string | null): boolean => {
	if (config !== null && config !== undefined) {
		return true;
	}
	return false;
};

export const getErrorMessage = (failedReason: string, status: number | null): string =>
	status === null ? `${failedReason}-unknown` : `${failedReason}-${status}`;

export const getDebugAttributes = (mode: string, statusCode: number | null, error: SLAError) => ({
	origin: mode, // Board or Backlog or Report,
	statusCode: statusCode === null ? 'unknown' : statusCode || 'unknown',

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	userAgent: navigator ? navigator.userAgent : 'unknown',
	isSPA: true,
	windowErrors: error,

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	href: window.location.href,

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	referrer: document.referrer,
});

export const shouldSLAFailureEventBeFired = (error: SLAError): boolean => {
	if (error !== null && error !== undefined) {
		const statusCode = getStatusCode(error); // Will return null or actual value

		if (statusCode === null) {
			return true;
		}
		if (statusCode >= 400 && statusCode < 500) {
			return false; // Should fire success sla
		}
		if (statusCode >= 500) {
			return true;
		}
		return false;
	}
	return false;
};
