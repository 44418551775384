import { useMemo } from 'react';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { editSprintModalEntryPoint } from '../../../../entrypoint';
import { editSprintModalLoad } from '../performance-analytics';

export const useEditSprintModalEntryPoint = (
	boardId: number,
	sprintId: string,
	isCompanyManaged: boolean,
) => {
	const cloudId = useCloudId();

	const boardAri = useMemo(
		() =>
			createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'board',
				resourceId: String(boardId),
			}),
		[boardId, cloudId],
	);

	const sprintAri = useMemo(
		() =>
			createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'sprint',
				resourceId: sprintId,
			}),
		[cloudId, sprintId],
	);

	const entryPointParams = useMemo(
		() => ({
			boardId: boardAri,
			sprintId: sprintAri,
			fetchSprints: false,
			isCompanyManaged,
		}),
		[boardAri, sprintAri, isCompanyManaged],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		editSprintModalEntryPoint,
		entryPointParams,
		editSprintModalLoad,
	);
	const editSprintModalTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	return {
		entryPointActions,
		entryPointReferenceSubject,
		stopMetric,
		editSprintModalTriggerRef,
	};
};
