import { GH_SPA_RB_READY } from '../../../constants';
import type { LoadDataProps, DeferredPromise } from '../types';

export const resolveDeferredWithPromise = async (
	promise: Promise<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data: Promise<Record<any, any>>;
	} | null> | null,
	deferred: DeferredPromise,
) => {
	if (!promise) {
		deferred.rejectWith(null);
		return;
	}
	try {
		const response = await promise;
		if (!response?.data) {
			deferred.rejectWith(null);
			return;
		}
		const data = await response.data;
		deferred.resolveWith(null, [data]);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		const { GH } = window;
		deferred.rejectWith(null, [
			GH.Ajax._convertFrameworkErrors(error),
			{ status: error.statusCode },
		]);
	}
};

export const getDeferredPromise = () => {
	const { AJS } = window;
	return AJS.$.Deferred();
};

const generateRapidBoardReadyListener =
	(
		onLoadData: (params: LoadDataProps) => void,
		onUpdateData: (data: Record<any, any>) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	() => {
		const { GH } = window;
		GH.RapidBoard.loadData = onLoadData;
		GH.RapidBoard.updateData = onUpdateData;
	};

export const setupRapidBoardDataLoader = (
	onLoadData: (params: LoadDataProps) => void,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUpdateData: (data: Record<any, any>) => void,
) => {
	const { GH } = window;
	const setupRapidBoard = generateRapidBoardReadyListener(onLoadData, onUpdateData);

	if (GH?.RapidBoard) {
		setupRapidBoard();
	} else {
		const listener = () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener(GH_SPA_RB_READY, listener);
			setupRapidBoard();
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener(GH_SPA_RB_READY, listener);
	}
};

export const cleanRapidBoardDataLoader = () => {
	const { GH } = window;
	if (GH?.RapidBoard) {
		delete GH.RapidBoard.loadData;
		delete GH.RapidBoard.updateData;
	}
};
