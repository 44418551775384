import clone from 'lodash/clone';
import compact from 'lodash/compact';
import defaults from 'lodash/defaults';
import each from 'lodash/each';
import extend from 'lodash/extend';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import kebabCase from 'lodash/kebabCase';
import keys from 'lodash/keys';
import last from 'lodash/last';
import map from 'lodash/map';
import min from 'lodash/min';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import createEvent from '@atlassian/jira-common-util-create-event';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import type { RouteResourceUpdater } from '@atlassian/react-resource-router';
import {
	INITIAL_DATA,
	CONFIG_DATA,
	BENTO_ROOT,
	ALL_DATA_PROMISE,
	GH_SPA_RB_READY,
	GH_SPA_RESOURCES_READY,
} from '../../../constants';
import { getSLAResult } from '../../sla-handler/utils';
import { addMetaToHead, addDivToDom } from './add-dom';
import { refresh } from './refresh';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		require?: any;
	}
}

const doHandshake = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.dispatchEvent(createEvent(GH_SPA_RESOURCES_READY));

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.removeEventListener(GH_SPA_RB_READY, doHandshake);
};

export const setupConfigResources = (
	dataPromise: Promise<unknown> | null,
	key: string,
	update: (getNewData: RouteResourceUpdater<unknown>) => void,
) => {
	const { GH } = window;
	if (!GH) {
		return;
	}
	GH.spa = GH.spa || { [INITIAL_DATA]: {} };
	GH.spa[CONFIG_DATA] = GH.spa[CONFIG_DATA] || {};
	if (dataPromise) {
		GH.spa[CONFIG_DATA][key] = dataPromise;
		GH.spa[CONFIG_DATA].key = key;
		GH.spa[CONFIG_DATA].refresh = refresh;
		GH.spa[CONFIG_DATA].update = update;
	}
};

export const baseSetUpWrmData =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(win: typeof window) => (allDataPromise: Promise<Record<any, any> | null>) => {
		const { GH } = win;
		if (!GH) {
			return;
		}
		GH.spa = GH.spa || { [INITIAL_DATA]: {} };
		if (allDataPromise) {
			GH.spa[INITIAL_DATA][ALL_DATA_PROMISE] = allDataPromise;

			win.addEventListener(GH_SPA_RB_READY, doHandshake);
			win.dispatchEvent(createEvent(GH_SPA_RESOURCES_READY));
		}
	};
export const setUpWrmData = baseSetUpWrmData(window);

export const baseSetUpHistory =
	(win: typeof window) =>
	(
		history: ReactRouterCompatibleHistory,
		urlState: {
			[key: string]: string | undefined | boolean | null;
		},
	) => {
		const { GH } = win;
		if (!GH) {
			return;
		}
		GH.spa = GH.spa || { [INITIAL_DATA]: {} };
		GH.spa.history = history;
		GH.spa.urlState = { ...urlState };
		GH.spa.lodash = {
			each,
			forEach,
			compact,
			reject,
			extend,
			isString,
			clone,
			isEmpty,
			kebabCase,
			min,
			sortBy,
			last,
			groupBy,
			isObject,
			map,
			keys,
			defaults,
			find,
			pick,
			has,
			throttle,
			flatten,
			isEqual,
		};
		GH.spa.slaUtil = getSLAResult;
	};
export const setUpHistory = baseSetUpHistory(window);

export const baseInitDOM =
	(doc: typeof document) =>
	(isBentoOn: boolean | null, projectKey?: string | null, projectId?: string): HTMLElement[] => {
		const sidebarMeta = addMetaToHead('ajs-sidebar-enabled', 'true');
		const atlGeneralMeta = addMetaToHead('decorator', 'atl.general');
		const projectIdMeta = addMetaToHead('ghx-project-id', projectId || '');
		const metaTags = [sidebarMeta, atlGeneralMeta, projectIdMeta];

		if (projectKey != null && projectKey !== '') {
			const projectKeyMeta = addMetaToHead('ghx-project-key', projectKey || '');
			metaTags.push(projectKeyMeta);
		} else {
			// SSR returns a project key meta tag for every SPA page so we need to manually remove it so rapidboards recognises the user context

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const projectKeyMeta = document.querySelector('meta[name="ghx-project-key"]');
			projectKeyMeta &&
				projectKeyMeta.parentNode &&
				projectKeyMeta.parentNode.removeChild(projectKeyMeta);

			const userMetaTag = addMetaToHead('ghx-container-type', 'USER');
			metaTags.push(userMetaTag);
		}

		if (doc.body) {
			const documentBody: HTMLElement = doc.body;
			documentBody.classList.remove('adg3');
			['aui-layout', 'aui-theme-default', 'adg3', 'ghx-agile'].forEach((className) => {
				documentBody.classList.add(className);
			});

			const bentoMeta = addMetaToHead('ghx-user-bento-enabled', 'true');
			metaTags.push(bentoMeta);
			addDivToDom(BENTO_ROOT);
			documentBody.classList.add('bento-enabled');

			documentBody.classList.add('ghx-ondemand');
		} else {
			log.safeErrorWithoutCustomerData('rapidboard-status.utils.set-up', 'body not in the dom');
		}

		// @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'HTMLElement[]'.
		return metaTags;
	};
export const initDOM = baseInitDOM(document);

export const resetWorkControllerFilters = () => {
	const { GH } = window;

	if (GH && GH.WorkController) {
		GH.WorkController.activeFilters.searchText = '';
		GH.WorkController.activeFilters.assigneeList = [];
	}
};

// When we wish to render the DOM skeleton for rapidboards to mount onto without running the
// whole initialisation process again we call this method
export const renderRapidboardDom = (isWorkMode = false) => {
	const { AJS, GH } = window;
	AJS.$('body').removeClass('ghx-board-configure');
	AJS.$('#gh').html(
		GH.tpl.board.x.renderRapidBoard({
			isWorkMode,
		}),
	);
	AJS.$('#ghx-operations').append(GH.tpl.viewcontroller.renderOperationsBar());
};

export const afterRenderSetup = () => {
	const { GH, AJS } = window;
	AJS.$(window).off(
		['blur.rapidboardSpa', 'unload.rapidboardSpa', 'focus.rapidboardSpa'].join(' '),
	);

	GH?.windowActivityNotifier?._bindFocusBlur();

	/**
	 * We need to unregister the SPA global issue create event handlers so that rapidboard can use its
	 * default ones which render the success and error flag in a different place.
	 *
	 * NOTE: do not destructure this above to avoid confusing the bundler.
	 */

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.require('jira/common/header').unbind();
};
