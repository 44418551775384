import React, { memo } from 'react';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import UserBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/user/view.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';
import type { Props } from './types';

export const UserBreadcrumbs = memo<Props>(
	({ accountId, boardId, boardName, profileUrl, userName, chart, isLocationlessBoard }: Props) => {
		const { formatMessage } = useIntl();

		if (isLocationlessBoard) {
			return (
				<UserBreadcrumb profileUrl={profileUrl} name={userName}>
					<Breadcrumb
						breadcrumb={{
							text: boardName,
							href: `/secure/RapidBoard.jspa?rapidView=${boardId}&locationless=true`,
							type: 'boardName',
						}}
					>
						{chart && (
							<Breadcrumb
								breadcrumb={{
									text: formatMessage(messages.userReportBreadcrumb),
									href: `/secure/RapidBoard.jspa?rapidView=${boardId}&view=reporting&chart=${chart}&locationless=true`,
								}}
							/>
						)}
					</Breadcrumb>
				</UserBreadcrumb>
			);
		}

		return (
			<UserBreadcrumb profileUrl={profileUrl} name={userName}>
				<Breadcrumb
					breadcrumb={{
						text: boardName,
						href: `/jira/people/${accountId}/boards/${boardId}`,
						type: 'boardName',
					}}
				>
					{chart && (
						<Breadcrumb
							breadcrumb={{
								text: formatMessage(messages.userReportBreadcrumb),
								href: `/jira/people/${accountId}/boards/${boardId}/reports/${chart}`,
							}}
						/>
					)}
				</Breadcrumb>
			</UserBreadcrumb>
		);
	},
);
