import React, { useEffect } from 'react';
import '@atlaskit/css-reset';
import { parse, stringify } from 'query-string';
import { useRapidBoardContentListener } from '@atlassian/jira-classic-software/src/controllers/rapidboard-content-listener/index.tsx';
import { useRapidBoardDataLoader } from '@atlassian/jira-classic-software/src/controllers/rapidboard-data/index.tsx';
import { useRapidBoard } from '@atlassian/jira-classic-software/src/controllers/rapidboard-state/index.tsx';
import { ArchivedProjectRedirect } from '@atlassian/jira-classic-software/src/ui/archived-project-redirect/index.tsx';
import { DocumentTitle } from '@atlassian/jira-classic-software/src/ui/document-title/index.tsx';
import { NextGenRedirect } from '@atlassian/jira-classic-software/src/ui/next-gen-redirect/index.tsx';
import { PerformanceMarks } from '@atlassian/jira-classic-software/src/ui/performance-marks/index.tsx';
import { PrettyUrlRedirect } from '@atlassian/jira-classic-software/src/ui/pretty-url-redirect/index.tsx';
import { RapidBoardApps } from '@atlassian/jira-classic-software/src/ui/rapidboard-app/async.tsx';
import IssueView from '@atlassian/jira-classic-software/src/ui/rapidboard-app/issue-view/async.tsx';
import { RapidBoardConfig } from '@atlassian/jira-classic-software/src/ui/rapidboard-config/async.tsx';
import { RapidboardContent } from '@atlassian/jira-classic-software/src/ui/rapidboard-content/main.tsx';
import { RoadmapSettingRedirect } from '@atlassian/jira-classic-software/src/ui/roadmap-setting-redirect/index.tsx';
import { SLATracker } from '@atlassian/jira-classic-software/src/ui/sla-tracker/main.tsx';
import { shouldIssueViewAppBeRendered } from '@atlassian/jira-classic-software/src/utils.tsx';
import { ROUTE_NAMES_SERVICEDESK_BOARDS } from '@atlassian/jira-common-constants/src/spa-routes';
import { DevDetailsDialogLoaderAsync } from '@atlassian/jira-development-details-loader/src/ui/async.tsx';
import { asyncRunInitBridge } from '@atlassian/jira-edit-sprint-modal-legacy-bridge/async';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { getActiveQuickFilters } from '@atlassian/jira-router-resources-classic-projects/src/utils/sticky-quickfilters/index.tsx';
import { useRapidBoardLongTasks } from '@atlassian/jira-software-browser-metrics/src/services/rapidboard-longtasks/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
// eslint-disable-next-line import/order
import { Redirect, useRouter, useQueryParam } from '@atlassian/react-resource-router';

import { useIsReportsPage } from './services/utils';

type Props = {
	history: ReactRouterCompatibleHistory;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ history }: Props) => {
	const { isRapidBoardsReady, mode, isBentoEnabled, shouldUserSeeBoard } = useRapidBoard(history);
	const contentLoadedStatus = useRapidBoardContentListener();

	if (ff('jsw.classic.board.api-calls.modern-frontend')) {
		useRapidBoardDataLoader();
	}

	const isClassicReportsPage = useIsReportsPage();
	const isRendered = contentLoadedStatus.ttr[mode] && isRapidBoardsReady;
	const isInteractive = contentLoadedStatus.tti[mode] && isRapidBoardsReady;

	const [modal] = useQueryParam('modal');
	const [selectedIssue] = useQueryParam('selectedIssue');
	const [view] = useQueryParam('view');

	const [config] = useQueryParam('config');
	const isConfigPage = typeof config === 'string' && config.length > 0;

	const [routerState, routerActions] = useRouter();
	const { name } = useCurrentRoute();

	useEffect(() => {
		if (!__SERVER__) {
			const queryParams = parse(routerState.location.search);
			const quickFilters = queryParams.quickFilter;
			const activeQuickFilters = getActiveQuickFilters(name);
			if (!quickFilters?.length && activeQuickFilters?.length) {
				queryParams.quickFilter = activeQuickFilters;
				routerActions.replace(`${routerState.location.pathname}?${stringify(queryParams)}`);
			}
		}
	}, [routerState, routerActions, name]);

	useRapidBoardLongTasks(name);

	if (isClassicReportsPage) {
		asyncRunInitBridge();
	}

	const shouldIssueViewLoad =
		shouldIssueViewAppBeRendered({ modal, selectedIssue, view }) || isInteractive;

	const subProductAnalytic = name === ROUTE_NAMES_SERVICEDESK_BOARDS ? 'serviceDesk' : 'software';

	return (
		<>
			{isRapidBoardsReady && <DocumentTitle />}
			{!shouldUserSeeBoard && <Redirect to="/jira/boards?showFlag=jsw.board-not-found" />}
			<SubProductUpdater subProduct={subProductAnalytic} />
			<PerformanceMarks isRendered={isRendered} isInteractive={isInteractive} />
			<NextGenRedirect />
			<RoadmapSettingRedirect />
			<PrettyUrlRedirect />
			<ArchivedProjectRedirect />
			<RapidboardContent
				isBentoEnabled={isBentoEnabled}
				isRapidBoardsReady={isRapidBoardsReady}
				mode={mode}
			/>
			<SLATracker />
			{shouldIssueViewLoad ? <IssueView shouldLoad={shouldIssueViewLoad} /> : null}
			{isInteractive ? <RapidBoardApps shouldLoad={isInteractive} /> : null}
			{isConfigPage ? <RapidBoardConfig shouldLoad={isConfigPage} /> : null}
			{isInteractive && (
				<ErrorBoundary
					packageName="jiraDevelopmentDetailsLoader"
					id="devDetailsDialogLoader"
					extraEventData={{ teamName: 'fusion-isotopes' }}
				>
					<DevDetailsDialogLoaderAsync scopeId="software-board.board" />
				</ErrorBoundary>
			)}
		</>
	);
};
