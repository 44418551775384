import React from 'react';
import { lazy } from 'react-loosely-lazy';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';

const LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY = 'LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY';

type Props = {
	baseUrl: string;
	rapidViewId: number;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyLocationlessDialogApp = lazy(
	() =>
		import(
			/* webpackChunkName: "async-locationless-dialog-app-defer" */ '@atlassian/jira-board-location-dialogs-locationless/src/app.tsx'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ErrorBoundary
		id="async.jira-board-location-dialogs-locationless.defer"
		packageName="jiraClassicBoardAndBacklog"
	>
		<Placeholder
			name="locationless-dialog-app"
			fallback={<PerformanceMark metricKey={LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY} />}
		>
			<LazyLocationlessDialogApp {...props} />
		</Placeholder>
	</ErrorBoundary>
);
