import { useRouter } from '@atlassian/react-resource-router';
import { getMode, getModeV2, toCamelCaseChartView } from './utils';

export const useUrlState = () => {
	const [{ query, route, match }] = useRouter();

	const { rapidView, chart, view, config, projectKey: queryProjectKey, locationless } = query;
	const { accountId } = match.params;

	const isBoardLocationless = !!locationless;
	const shouldUsePrettyUrlState = !isBoardLocationless;

	const mode = shouldUsePrettyUrlState ? getModeV2(route) : getMode(view);
	const boardId = shouldUsePrettyUrlState ? match.params.boardId : rapidView;
	const projectKey = shouldUsePrettyUrlState ? match.params.projectKey : queryProjectKey;
	const chartView = shouldUsePrettyUrlState ? toCamelCaseChartView(match.params.chartView) : chart;

	return {
		accountId,
		mode,
		boardId,
		chartView,
		projectKey,
		routeName: route.name,
		config,
		isBoardLocationless,
	};
};
