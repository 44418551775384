import getMeta from '@atlassian/jira-get-meta';
import type { RouteResourceError } from '@atlassian/react-resource-router';
import { PLAN, WORK } from './constants';

type IssueViewParams = {
	modal?: string;
	selectedIssue?: string;
	view?: string;
};

export const isPlanningMode = (mode: string) => mode === PLAN;

export const isWorkMode = (mode: string) => mode === WORK;

export const isAllDataErrorValid = (error: RouteResourceError) =>
	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'RouteResourceError'. | TS2339 - Property 'statusCode' does not exist on type 'RouteResourceError'. | TS2339 - Property 'statusCode' does not exist on type 'RouteResourceError'.
	!!(error && error.statusCode !== 400 && error.statusCode !== 404 && error.statusCode !== 500);

export const shouldIssueViewAppBeRendered = ({
	modal,
	selectedIssue,
	view,
}: IssueViewParams): boolean => {
	if (!__SERVER__) {
		const isUserBentoEnabled = getMeta('ghx-user-bento-enabled') === 'true';
		const isIssueViewOpen =
			(modal === 'detail' || view === 'detail' || view === 'planning') && selectedIssue;
		return isUserBentoEnabled && !!isIssueViewOpen;
	}
	return false;
};
