import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { rapidboardResource } from '@atlassian/jira-router-resources-classic-projects/src/services/rapidboard-resource/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { messages } from './messages';

export const IssueActions = () => {
	const { data } = useResource(rapidboardResource);
	const { formatMessage } = useIntl();
	// @ts-expect-error - TS7019 - Rest parameter 'params' implicitly has an 'any[]' type. | TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
	const wrappedFormatMessage = (...params) => formatMessage(...params, { '0': '{0}' });

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className="hidden js-issueaction-container-dot">
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{data?.issueOperations?.map((action: any) => (
					<a
						key={action.id + action.style}
						id={action.id}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`js-issueaction ${action.style}`}
						href={action.url}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<span className="hidden">Link for {action.id}</span>
					</a>
				))}
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<fieldset className="hidden parameters">
				<input
					type="hidden"
					id="thanks_issue_updated"
					value={wrappedFormatMessage(messages.issueUpdated)}
				/>
				<input
					type="hidden"
					id="thanks_issue_transitioned"
					value={wrappedFormatMessage(messages.issueUpdated)}
				/>
				<input
					type="hidden"
					id="thanks_issue_assigned"
					value={wrappedFormatMessage(messages.issueAssigned)}
				/>
				<input
					type="hidden"
					id="thanks_issue_commented"
					value={wrappedFormatMessage(messages.issueCommented)}
				/>
				<input
					type="hidden"
					id="thanks_issue_worklogged"
					value={wrappedFormatMessage(messages.issueWorklogged)}
				/>
				<input
					type="hidden"
					id="thanks_issue_voted"
					value={wrappedFormatMessage(messages.issueVoted)}
				/>
				<input
					type="hidden"
					id="thanks_issue_watched"
					value={wrappedFormatMessage(messages.issueWatched)}
				/>
				<input
					type="hidden"
					id="thanks_issue_moved"
					value={wrappedFormatMessage(messages.issueMoved)}
				/>
				<input
					type="hidden"
					id="thanks_issue_linked"
					value={wrappedFormatMessage(messages.issueLinked)}
				/>
				<input
					type="hidden"
					id="thanks_issue_cloned"
					value={wrappedFormatMessage(messages.issueCloned)}
				/>
				<input
					type="hidden"
					id="thanks_issue_labelled"
					value={wrappedFormatMessage(messages.issueLabelled)}
				/>
				<input
					type="hidden"
					id="thanks_issue_deleted"
					value={wrappedFormatMessage(messages.issueDeleted)}
				/>
				<input
					type="hidden"
					id="thanks_issue_attached"
					value={wrappedFormatMessage(messages.issueAttached)}
				/>
			</fieldset>
		</>
	);
};
