import { useEffect, useState } from 'react';
import { useSpaStateActions } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { GH_SPA_CONTENT_READY, GH_SPA_CONTENT_LOADING } from '../../constants';
import { useUrlState } from '../url-state';
import { initialState, generateContentLoadingListener, generateContentDoneListener } from './utils';

export const useRapidBoardContentListener = () => {
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [contentLoadStatus, setHasContentLoaded] = useState(initialState);
	const [, { setAppReady }] = useSpaStateActions();
	const { mode, boardId, chartView } = useUrlState();

	useEffect(() => {
		let ttrListener: ReturnType<typeof generateContentLoadingListener>;
		if (isFirstLoad) {
			ttrListener = generateContentLoadingListener(setHasContentLoaded);
			// @ts-expect-erro - TS2769 - No overload matches this call.

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener(GH_SPA_CONTENT_LOADING, ttrListener);
			setIsFirstLoad(false);
		}

		const ttiListener = generateContentDoneListener(setHasContentLoaded, setAppReady);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener(GH_SPA_CONTENT_READY, ttiListener);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			ttrListener && window.removeEventListener(GH_SPA_CONTENT_LOADING, ttrListener);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener(GH_SPA_CONTENT_READY, ttiListener);
		};
	}, [setAppReady, boardId, mode, chartView, isFirstLoad]);

	return contentLoadStatus;
};

export const useRapidBoardContentLoadListener = () => {
	const [isAppContentLoaded, setIsAppContentLoaded] = useState<boolean>(false);
	useEffect(() => {
		const appLoadedListener = () => {
			!isAppContentLoaded && setIsAppContentLoaded(true);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener(GH_SPA_CONTENT_READY, appLoadedListener);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener(GH_SPA_CONTENT_READY, appLoadedListener);
		};
	}, [isAppContentLoaded]);

	return [isAppContentLoaded, setIsAppContentLoaded] as const;
};
